<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 align-self-center>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <v-layout wrap justify-end>
            <v-flex align-self-center text-left>
              <span class="itemHeading">Add Employee</span>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xl3 lg3 md3 py-2 sm3 xs12 px-2 align-self-center>
              <v-text-field
                v-model="keysearch"
                outlined
                class="rounded-xl"
                dense
                hide-details
                clearable
                label="Search Employee"
              >
              </v-text-field>
            </v-flex>
            <v-flex
                xs12
                sm3
                md3
                lg2
                xl2
                pa-1
                pt-2
                text-left
                style="font-family: poppinsregular"
              >
                <v-btn
                  dark
                  block
                  class="rounded-xl"
                  color="#005f32"
                  @click="employeeListDownload()"
                >
                Excel Download
                </v-btn>
              </v-flex>
            <v-flex
              xl3
              lg3
              md3
              pb-2
              sm3
              xs12
              px-0
              pl-0
              pl-sm-5
              align-self-center
            >
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="font-family: kumbhMedium"
                    block
                    color="#005f32"
                    dark
                    class="rounded-xl"
                    v-bind="attrs"
                    v-on="on"
                    @click="getUsers1(), getUsers2(), getUsers3()"
                  >
                    Add Employee
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addcat" ref="addcat">
                    <v-layout wrap>
                      <v-flex
                        xs12
                        pa-4
                        text-left
                        align-self-center
                        style="background: #005f32 !important"
                      >
                        <!-- <span
                        class="kumbhBold"
                        style="color: #ffffff; font-size: 20px"
                        >Add Employee</span
                      > -->

                        <v-layout wrap>
                          <v-flex xs6 align-self-center text-left>
                            <span
                              class="kumbhBold"
                              style="color: #ffffff; font-size: 20px"
                              >Add Employee</span
                            >
                          </v-flex>
                          <v-flex xs6 align-self-center text-right>
                            <v-btn
                              icon
                              color="white"
                              text
                              @click="dialog = false"
                            >
                              <v-icon color="#ffffff">mdi-close</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-4></v-flex>
                      <v-flex xs12 align-self-center px-3>
                        <v-layout wrap>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.employeeId"
                              label="Employee ID"
                              :rules="[rules.required]"
                              required
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.name"
                              label="Employee Name"
                              :rules="[rules.required]"
                              required
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>

                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.email"
                              label="Email"
                              :rules="emailRules"
                              required
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="user.dob"
                                  label="DOB"
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="user.dob"
                                no-title
                                min="1930-01-01"
                                :max="maxDate"
                                @input="
                                  menu = false;
                                  getAge(user.dob);
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.age"
                              label="Age"
                              readonly
                              type="number"
                              outlined
                              @wheel="$event.target.blur()"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.phonenumber"
                              label="Phone Number"
                              :rules="phnoRules"
                              required
                              outlined
                              @wheel="$event.target.blur()"
                              type="number"
                              dense
                            ></v-text-field>
                          </v-flex>

                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-textarea
                              v-model="user.address"
                              label="Address"
                              :rules="[rules.required]"
                              required
                              outlined
                              dense
                            ></v-textarea>
                          </v-flex>

                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-radio-group
                              dense
                              hide-details
                              v-model="user.gender"
                              column
                              label="Gender"
                            >
                              <v-radio label="Male" value="Male"></v-radio>
                              <v-radio label="Female" value="Female"></v-radio>
                              <v-radio label="Other" value="Other"></v-radio>
                            </v-radio-group>
                          </v-flex>

                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-menu
                              ref="menu2"
                              v-model="menu2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="user.dateofjoining"
                                  label="Date of joining"
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="user.dateofjoining"
                                no-title
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-menu
                              ref="menu3"
                              v-model="menu3"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="user.dateofProbationCompletion"
                                  label="Date of probation completion"
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="user.dateofProbationCompletion"
                                no-title
                                @input="menu3 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>

                          <!-- <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.salary"
                              label="Salary"
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex> -->

                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.grade"
                              label="Grade"
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-select
                              v-model="user.location"
                              :items="locationList"
                              item-color="#005f32"
                              color="#005f32"
                              outlined
                              dense
                              label="Choose Location"
                            >
                            </v-select>
                          </v-flex>

                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.officialEmail"
                              label="Official Email address"
                              :rules="[rules.required]"
                              required
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.monthlyCTC"
                              label="Monthly CTC"
                              :rules="[rules.required]"
                              required
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.yearlyPackage"
                              label="Yearly Package"
                              :rules="[rules.required]"
                              required
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex
                          xs12
                          sm6
                          align-self-center
                          text-left
                          pa-2
                          px-4
                        >
                          <v-select
                            v-model="user.employeeStatus"
                            :items="statusList"
                            item-text="name"
                            item-value="name"
                            item-color="#005f32"
                            color="#005f32"
                            outlined
                            dense
                            label="Choose Employee status"
                          >
                          </v-select>
                        </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-textarea
                              v-model="user.officeAddress"
                              label="Office Address"
                              :rules="[rules.required]"
                              required
                              outlined
                              dense
                            ></v-textarea>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-textarea
                              v-model="user.education"
                              label="Education - Degree/ Diploma"
                              :rules="[rules.required]"
                              required
                              outlined
                              dense
                            ></v-textarea>
                          </v-flex>
                      

                          <!-- <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-select
                              v-model="department"
                              :items="departmentList"
                              item-text="deptName"
                              item-value="_id"
                              outlined
                              multiple
                              dense
                              :rules="[rules.required]"
                              required
                              label="Choose Department"
                            >
                            </v-select>
                          </v-flex> -->
                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <!-- <v-select
                              v-model="project"
                              :items="projectList"
                              item-text="projectName"
                              multiple
                              item-value="_id"
                              outlined
                              dense
                              label="Choose Project"
                            >
                            </v-select> -->

                            <v-autocomplete
                            v-model="project"
                            :items="projectList"
                            item-text="projectName"
                            multiple
                            item-value="_id"
                            outlined
                            dense
                            label="Choose Project"
                  
                      ></v-autocomplete>


                          </v-flex>

                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                          <v-autocomplete
                              v-model="user.positionid"
                              :items="positionList"
                              item-text="name"
                              item-value="_id"
                              outlined
                              dense
                              label="Choose Position(s)"
                            >
                        </v-autocomplete>
                          </v-flex>

                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-text-field
                              v-model="user.role"
                              label="Role"
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                        
                          

                          <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-autocomplete
                              v-model="user.administrativeReportingManagersIds"
                              :items="empList1"
                              item-text="name"
                              :search-input.sync="search1"
                              item-value="_id"
                              outlined multiple
                             
                              label="Choose Administrative Reporting Manager"
                              dense
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex
                          xs12
                          sm6
                          align-self-center
                          text-left
                          pa-2
                          px-4
                        > 
                        <!-- <v-select
                          v-model="user.technicalReportingManager"
                          :items="empList2"
                          item-text="name"
                          item-value="_id"
                          outlined
                          dense
                          label="Choose Technical Reporting Manager"
                        >
                        </v-select> -->

                 <v-autocomplete
                            v-model="user.technicalReportingManagersIds"
                            :items="empList2"
                            item-text="name"
                            :search-input.sync="search22"
                            item-value="_id"
                            outlined multiple
                           
                            label="Choose Technical Reporting Manager"
                            dense
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          >
                            <v-autocomplete
                              v-model="user.principalInvigilator"
                              :items="empList3"
                              item-text="name"
                              :search-input.sync="search3"
                              item-value="_id"
                              outlined
                             
                              label="Choose Principal Invigilator/Investigator"
                              dense
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12 pa-4 pb-12 align-self-center>
                            <v-divider></v-divider>
                          </v-flex>

                          <!-- <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          > -->
                          <!-- <v-select
                            v-model="user.administrativeReportingManager"
                            :items="empList1"
                            item-text="name"
                            item-value="_id"
                            outlined
                            dense
                            label="Choose Administrative Reporting Manager"
                          >
                          </v-select> -->
                          <!-- <v-autocomplete
                              v-model="user.administrativeReportingManager"
                              :items="empList1"
                              item-text="name"
                              :search-input.sync="search11"
                              item-value="_id"
                              outlined
                              hide-details
                              label="Choose Administrative Reporting Manager"
                              dense
                            ></v-autocomplete>
                          </v-flex> -->

                        

                          <!-- <v-flex
                            xs12
                            sm6
                            align-self-center
                            text-left
                            pa-2
                            px-4
                          > -->
                          <!-- <v-select
                            v-model="user.principalInvigilator"
                            :items="empList3"
                            item-text="name"
                            item-value="_id"
                            outlined
                            dense
                            label="Choose Principal Invigilator/Investigator"
                          >
                          </v-select> -->
                          <!-- 
                            <v-autocomplete
                              v-model="user.principalInvigilator"
                              :items="empList3"
                              item-text="name"
                              :search-input.sync="search33"
                              item-value="_id"
                              outlined
                              hide-details
                              label="Choose Principal Invigilator/Investigator"
                              dense
                            ></v-autocomplete>
                          </v-flex> -->
                          <!-- <v-flex xs12 align-self-center pa-2>
                            <v-divider></v-divider>
                          </v-flex> -->

                          <v-flex xs12 sm6 pa-2 px-4 v-if="flagg">
                            <ImageComp
                              @stepper="winStepper"
                              :height="'320'"
                              :width="'320'"
                              :heading="'Upload Profile Picture'"
                              :componentType="'coverImage'"
                            />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addcat"
                        @click="validateEmp()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>

            <v-flex xs12>
              <v-spacer></v-spacer>
            </v-flex>
            <v-spacer></v-spacer>

            <v-flex
              xl3
              lg3
              align-self-center
              md3
              py-2
              sm3
              xs12
              px-2
              v-if="moreFilter"
            >
              <v-select
                v-model="ffdepartment"
                :items="ffdepartmentList"
                item-text="deptName"
                item-value="_id"
                outlined
                class="rounded-xl"
                dense
                clearable
                label="Department"
              >
              </v-select>
            </v-flex>
            <v-flex
              xl3
              lg3
              align-self-center
              md3
              py-2
              sm3
              xs12
              px-2
              pr-3
              v-if="moreFilter"
            >
              <v-select
                v-model="ffproject"
                :items="ffprojectList"
                item-text="projectName"
                item-value="_id"
                outlined
                class="rounded-xl"
                dense
                clearable
                label="Project"
              >
              </v-select>
            </v-flex>
            <v-flex
              xl3
              align-self-center
              lg3
              md3
              py-2
              sm3
              xs12
              px-2
              pr-3
              v-if="moreFilter"
            >
              <v-select
                v-model="ffposition"
                :items="ffpositionList"
                item-text="name"
                item-value="_id"
                outlined
                class="rounded-xl"
                dense
                clearable
                label="Positions"
              >
              </v-select>
            </v-flex>
            <v-flex xs2 align-self-start text-right pb-2 sm3 px-5>
              <span
                v-if="!moreFilter"
                @click="moreFilter = true"
                style="font-size: 14px; cursor: pointer"
                class="kumbhMedium"
              >
                <v-icon>mdi-filter-variant-plus</v-icon> More filters..</span
              >
              <span
                v-if="moreFilter"
                @click="
                  (moreFilter = false), (project = null), (department = null)
                "
                style="font-size: 14px; cursor: pointer"
                class="kumbhMedium"
                ><v-icon>mdi-filter-variant-minus</v-icon>Show less</span
              >
            </v-flex>
          </v-layout>
        </v-card-title>

        <v-layout wrap justify-start>
          <v-flex xs12 align-self-center pa-4>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr class="kumbhEBold">
                    <th class="text-left">Name</th>
                    <th class="text-left">E-mail</th>
                    <th class="text-left">Employee Id</th>
                    <th class="text-center">View Profile</th>
                    <th class="text-center">Edit</th>
                  </tr>
                </thead>
                <tbody class="kumbhRegular">
                  <tr v-for="(item, i) in employeeList" :key="i">
                    <!-- <td class="text-left">
                      <span v-if="item.photo" class="rounded-lg">
                        <v-img
                          contain
                          height="42px"
                          width="42px"
                          class="rounded-lg"
                          :src="mediaURL + item.photo"
                        ></v-img>
                      </span>
                      <span v-else class="rounded-lg">
                        <v-img
                          contain
                          height="42px"
                          width="42px"
                          class="rounded-lg"
                          :src="require('./../../assets/images/nopic.jpg')"
                        ></v-img>
                      </span>
                    </td> -->

                    <td class="text-left">
                      <span v-if="item.name">
                        {{ item.name }}
                      </span>
                      <span v-else> - </span>
                      <!-- <span v-else-if="item.email">
                        {{ item.email }}
                      </span> -->
                    </td>
                    <td class="text-left">
                      <span>{{ item.email }}</span>
                    </td>

                    <td class="text-left">
                      <span>{{ item.employeeId }}</span>
                    </td>

                    <td class="text-left">
                      <v-layout wrap justify-center>
                        <v-flex xs6 sm4 md2 pa-2>
                          <v-btn
                            color="#005f32"
                            style="font-family: kumbhMedium"
                            small
                            title="View details"
                            block
                            icon
                            :to="'/viewEmployee?id=' + item._id"
                          >
                            <v-icon color="#005f32">
                              mdi-eye-settings-outline
                            </v-icon>
                          </v-btn>
                        </v-flex>

                        <!-- <v-flex xs6 sm4 md2 pa-2>
                          <v-dialog
                            persistent
                            v-model="item.delete"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                style="font-family: kumbhMedium"
                                class="mr-2"
                                icon
                                title="Delete"
                                block
                                v-on="on"
                                v-bind="attrs"
                                color="#005f32"
                              >
                                <v-icon> mdi-delete-alert-outline </v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                  text-left
                                >
                                  <span class="kumbhBold" style="color: #ffffff"
                                    >Delete Confirmation</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-card-title
                                >Are you sure you want to delete this
                                Employee?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="error"
                                  tile
                                  dark
                                  @click="item.delete = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="#005f32"
                                  outlined
                                  tile
                                  @click="deleteItem(item)"
                                  >Delete</v-btn
                                > 
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex> -->
                      </v-layout>
                    </td>
                    <td class="text-left">
                      <v-layout wrap>
                        <v-flex xs6 sm4 md2 pa-2>
                          <v-btn
                            color="#005f32"
                            style="font-family: kumbhMedium"
                            small
                            title="Edit"
                            block
                            icon
                            @click="
                              editcat(item), (test = editingitem.positionid[0])
                            "
                          >
                            <v-icon> mdi-circle-edit-outline </v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap justify-start>
          <v-flex
            xs12
            md6
            sm6
            lg4
            xl3
            align-self-center
            v-for="(item, i) in employeeList"
            :key="i"
            fill-height
            pa-4
          >
            <v-card tile flat outlined>
              <v-layout wrap justify-center py-4>
                <v-flex xs12 align-self-start text-left pa-3>
                  
                  <span style="font-family: kumbhMedium">{{ item.email }}</span>
                </v-flex>

                <v-flex xs12 sm6 lg6 pa-2>
                  <v-btn
                    color="success"
                    style="font-family: kumbhMedium"
                    small
                    block
                    outlined
                    @click="editcat(item)"
                    >Edit</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 lg6 pa-2>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: kumbhMedium"
                        class="mr-2"
                        outlined
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Employee?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>


         
        </v-layout> -->
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-dialog
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="editdialog"
    >
      <v-card tile>
        <v-layout wrap>
          <v-flex
            xs12
            pa-4
            text-left
            align-self-center
            style="background: #005f32 !important"
          >
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Edit Employee</span
                >
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="getdData">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12 align-self-center px-3>
            <v-layout wrap>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.employeeId"
                  label="Employee ID"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.name"
                  label="Employee Name"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.email"
                  label="Email"
                  :rules="emailRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-menu
                  ref="menu"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editingitem.dob"
                      label="DOB"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editingitem.dob"
                    no-title
                    min="1930-01-01"
                    :max="maxDate"
                    @input="(menu1 = false), getAge(editingitem.dob)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.age"
                  label="Age"
                  readonly
                  :rules="[rules.required]"
                  required
                  type="number"
                  outlined
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.phonenumber"
                  :rules="phnoRules"
                  required
                  outlined
                  @wheel="$event.target.blur()"
                  type="number"
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-textarea
                  v-model="editingitem.address"
                  label="Address"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-radio-group
                  dense
                  hide-details
                  v-model="editingitem.gender"
                  column
                  label="Gender"
                >
                  <v-radio label="Male" value="Male"></v-radio>
                  <v-radio label="Female" value="Female"></v-radio>
                  <v-radio label="Other" value="Other"></v-radio>
                </v-radio-group>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-menu
                  ref="menu2"
                  v-model="menu22"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editingitem.dateofjoining"
                      label="Date of joining"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editingitem.dateofjoining"
                    no-title
                    @input="menu22 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <!-- <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.salary"
                  label="Salary"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex> -->

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.grade"
                  label="Grade"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editingitem.dateofProbationCompletion"
                      label="Date of probation completion"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editingitem.dateofProbationCompletion"
                    no-title
                    @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-select
                  v-model="editingitem.location"
                  :items="locationList"
                  item-color="#005f32"
                  color="#005f32"
                  outlined
                  dense
                  label="Choose Location"
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.officialEmail"
                  label="Official Email address"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.monthlyCTC"
                  label="Monthly CTC"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.yearlyPackage"
                  label="Yearly Package"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-select
                  v-model="editingitem.employeeStatus"
                  :items="statusList"
                  item-text="name"
                  item-value="name"
                  item-color="#005f32"
                  color="#005f32"
                  outlined
                  dense
                  label="Choose Employee status"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-textarea
                  v-model="editingitem.officeAddress"
                  label="Office Address"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-textarea
                  v-model="editingitem.education"
                  label="Education - Degree/ Diploma"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-flex>
              

              <!-- <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-select
                  v-model="department"
                  :items="departmentList"
                  item-text="deptName"
                  item-value="_id"
                  outlined
                  multiple
                  dense
                  :rules="[rules.required]"
                  required
                  label="Choose Department"
                >
                </v-select>
              </v-flex> -->
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-autocomplete
                  v-model="editingitem.projects"
                  :items="projectList"
                  item-text="projectName"
                  multiple
                  item-value="_id"
                  outlined
                  dense
                  label="Choose Project"
                >
                </v-autocomplete>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-autocomplete
                  v-model="test"
                  :items="positionList"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  label="Choose Position(s)"
                >
                </v-autocomplete>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.roleName"
                  outlined
                  dense
                  clearable
                  label="Role"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-menu
                  ref="menu4"
                  v-model="menu4"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editingitem.exitDate"
                      label="Exit Date"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editingitem.exitDate"
                    no-title
                    @input="menu4 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-select
                  v-model="editingitem.exitType"
                  :items="reasonsList"
                  outlined
                  dense
                  label="Exit Type"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-textarea
                  v-model="editingitem.exitReason"
                  label="Exit Reason"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-textarea
                  v-model="editingitem.eligibilitytoRehire"
                  label="Eligibility to rehire"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-textarea
                  v-model="editingitem.promotion"
                  label="Promotions"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-textarea
                  v-model="editingitem.others"
                  label="Others"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-flex>
              <v-flex xs12 pa-4 pb-12 align-self-center>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <!-- <v-select
                  v-model="editingitem.administrativeReportingManager"
                  :items="empList"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  label="Choose Administrative Reporting Manager"
                >
                </v-select> -->

                <v-autocomplete
                  v-model="editingitem.administrativeReportingManagersIds"
                  :items="empList1"
                  item-text="name"
                  :search-input.sync="search1"
                  item-value="_id"
                  outlined multiple
                  hide-details
                  label="Choose Administrative Reporting Manager"
                  dense
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <!-- <v-select
                  v-model="editingitem.technicalReportingManager"
                  :items="empList2"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  label="Choose Technical Reporting Manager"
                >
                </v-select> -->
             <v-autocomplete
                  v-model="editingitem.technicalReportingManagersIds"
                  :items="empList2"
                  item-text="name"
                  :search-input.sync="search2"
                  item-value="_id"
                  outlined multiple
                  hide-details
                  label="Choose Technical Reporting Manager"
                  dense
                ></v-autocomplete> 
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <!-- <v-select
                  v-model="editingitem.principalInvigilator"
                  :items="empList3"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  label="Choose Principal Invigilator/Investigator"
                >
                </v-select> -->

                <v-autocomplete
                  v-model="editingitem.principalInvigilator"
                  :items="empList3"
                  item-text="name"
                  :search-input.sync="search3"
                  item-value="_id"
                  outlined
                  hide-details
                  label="Choose Principal Invigilator/Investigator"
                  dense
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 pa-4 align-self-center>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 sm6 pa-2 px-4 text-left v-if="flagg">
                <ImageComp
                  @stepper="winStepper"
                  :singleImage="editingitem.photo"
                  :pageId="editingitem._id"
                  :height="'320'"
                  :width="'320'"
                  :heading="'Upload Profile Pic'"
                  :componentType="'coverImage'"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="getdData"> Close </v-btn>
          <v-btn v-if="!g" color="blue darken-1" text @click="validateEdit()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ImageComp from "./singleImage";
import axios from "axios";
export default {
  props: ["employeeData", "dialogValue"],
  data() {
    return {
      //searchproject:"",
      test: null,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      doc: "",
      locationList: [
        "HQ-Noida",
        "CWRC-Assam",
        "Dakopgre, Tura, Meghalaya",
        "Bansbari Field Station- Manas National Park - Assam",
        "Panbari",
        "Kaziranga Discovery Park",
        "Udanti Field Station, Chhattisgarh",
        "Arunachal Pradesh",
        "Similipal, Odisha",
        "Veraval- Gujarat",
        "Kokrajhar, Assam",
        "Field Station Charikhora, Kokrajhar (MVS) Park, Assam",
        "Edat Field Station, Kannur",
        "Valmiki Tiger Reserve, Bihar",
        "Nichlaul, Maharajganj, UP",
        "Dakopgre, Tura, Meghalaya",
        "Guijan, Assam",
        "Manas National Park - Assam",
        "Brahmpuri - Maharashtra",
        "Tirunelli - Wayanad, Kerala",
        "Field Camp Doimari, (Elephant Rehab), Manas National Park, Assam",
        "Ujjain",
        "Mithapur- Gujarat",
        "Hirpora, J&K",
        "Baripada, Similipal,Odisha",
        "Munnar,Kerala",
        "Gurguria",
        "Nichlaul, Maharajganj, UP",
        "Dakopgre, Tura, Meghalaya",
        "Panbari Field Station-CWRC",
        "Dakopgre, Tura, Meghalaya",
        "Baghmara",
        "Dudhwa",
        "Bandipur/Nagarhole",
        "J&K",
        "Keonjhar",
        "Kerala",
        "Katarniaghat",
        "Sanjay Tiger reserve",
        "Bandhavgarh",
        "Wayanad",
        "Kannur",
        "Manipur",
        "Pilibhit/Katerniaghat",
        "Central India",
        "Katarniaghat",
        "Gondia",
        "Assam",
        "Similipal, Odisha",
      ],
      statusList: [
        {
          name: "On Probation",
        },
        {
          name: "Confirmed",
        },
      ],
      reasonsList: ["Primary", "Secondary", "Local resignation/termination"],
      rules: {
        required: (value) => !!value || "Required.",
      },
      employeeList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      role: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      user: {
        dob: new Date(),
        dateofjoining: new Date(),
        dateofProbationCompletion: new Date(),
        exitDate: new Date(),
      },
      file: "",
      menu: false,
      menu1: false,
      menu2: false,
      menu22: false,
      menu3: false,
      menu4: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      roleRules: [(v) => !!v || "Role is required"],
      phnoRules: [
        (v) => !!v || "Phone is required",
        (v) => /^\d{10}$/.test(v) || "Phone is invalid",
      ],
      departmentList: [],
      projectList: [],
      positionList: null,
      project: null,
      department: null,
      empList1: [],
      empList2: [],
      empList3: null,
      search1: null,
      search2: null,
      search3: null,
      search11: null,
      search22: null,
      searchInput: null,
      search33: null,
      keysearch: null,
      ffprojectList: null,
      ffproject: null,
      ffdepartmentList: null,
      ffdepartment: null,
      ffpositionList: null,
      ffposition: null,
      moreFilter: false,
      maxDate: this.calculateMaxDate(),
    };
  },
  components: {
    ImageComp,
  },
  watch: {
    // searchproject(val) {
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.getffProject(val);
    //     this.loading = false;
    //   }, 500);
    // },
    department() {
      this.user.positionid = null;
      this.getPositions();
    },
    project() {
      this.user.positionid = null;
      // this.getPositions();
    },
    search1() {
      this.search = this.search1;
      this.getUsers1();
    },
    search2() {
      this.search = this.search2;
      this.getUsers2();
    },
    search3() {
      this.search = this.search3;
      this.getUsers3();
    },
    search11() {
      this.search = this.search11;
      this.getUsers1();
    },
    search22() {
      this.search = this.search22;
      this.getUsers2();
    },
    search33() {
      this.search = this.search33;
      this.getUsers3();
    },
    keysearch() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    currentPage() {
      this.getData();
      this.$store.commit("changeCurrentPage", this.currentPage);
    },
    ffdepartment() {
      this.currentPage = 1;
      this.getffProject();
      this.getFilterPositions();
    },
    ffproject() {
      this.currentPage = 1;
      this.getFilterPositions();
    },
    ffposition() {
      this.currentPage = 1;
      this.getData();
    },
  },
  beforeMount() {
    this.getffDept();
    this.getDept();
    this.getProject();
    this.getPositions();
    if (this.$store.state.currentPage) {
      this.currentPage = this.$store.state.currentPage;
      this.getData();
    }
  },
  mounted() {
    // this.getUsers("");
    if (this.user.dob) {
      this.user.dob = null;
      this.user.dateofjoining = null;
      this.user.dateofProbationCompletion = null;
      // this.user.dob.setDate(this.user.dob.getDate());
      // this.user.dob = this.user.dob.toISOString().substr(0, 10);
    }

    this.getData();
    if (this.$route.query.dialogValue == "true") {
      this.editdialog = true;
      //  this.editingitem._id = this.$route.query.id;

      this.editingitem._id = this.$route.query.employeeData._id;
      this.editingitem.name = this.$route.query.employeeData.name;
      this.editingitem.email = this.$route.query.employeeData.email;
      this.editingitem.phonenumber = this.$route.query.employeeData.phonenumber;
      // this.editingitem.employeeId = this.$route.query.employeeData.employeeId;
      this.editingitem.employeeStatus =
        this.$route.query.employeeData.employeeStatus;
    }
  },

  methods: {
    calculateMaxDate() {
      // Create a new Date object for the current date
      const now = new Date();
      // Subtract 17 years from the current year
      const year = now.getFullYear() - 18;
      // Set the maxDate to December 31st of the calculated year
      const maxDate = new Date(year, 11, 31); // 11 represents December
      // Format the date in YYYY-MM-DD format (Vuetify expects this format)
      return maxDate.toISOString().split("T")[0];
    },
    addExp(data, index) {
      this.experienceArray[index].experience.push(data);
    },
    employeeListDownload() {
      axios({
        method: "GET",
        url: "/employees/getalllist/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          from: this.fromDate,
          to: this.toDate,
          id: this.member,
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Employee_List.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getFilterPositions() {
      this.appLoading = true;
      axios({
        url: "/getalllist/position/v1",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          projectid: this.project,
          isDetailed: true,
          departmentIds: this.ffdepartment,
          positionid: this.positionid,
          projectIds: this.ffproject,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.ffpositionList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getffDept() {
      this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.ffdepartmentList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getffProject() {
      this.appLoading = true;
      axios({
        url: "/projects/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          departmentId: this.ffdepartment,
          // keyword: val,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.ffprojectList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    winStepper(window_data) {
      if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
      }
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      // return age;
      this.user.age = age;
      this.editingitem.age = age;
    },

    getUsers1() {
      this.appLoading = true;
      axios({
        url: "/employees/getalllist/search",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          except: this.editingitem._id,
          keyword: this.search,
        
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList1 = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getUsers2() {
      this.appLoading = true;
      axios({
        url: "/employees/getalllist/search",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          except: this.editingitem._id,
          keyword: this.search,
         
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList2 = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getUsers3() {
      this.appLoading = true;
      axios({
        url: "/employees/getalllist/search",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          except: this.editingitem._id,
          keyword: this.search,
         
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList3 = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getDept() {
      this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.departmentList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProject() {
      this.appLoading = true;
      axios({
        url: "/projects/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.projectList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getPositions() {
      this.appLoading = true;
      axios({
        url: "/getalllist/position/v1",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          isDetailed: true,
          departmentIds: this.department,
          projectIds: this.project,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.positionList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/job/employeeList/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      // this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keysearch,
          page: this.currentPage,
          limit: 20,
          positionid: this.ffposition,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeList = response.data.data;

          this.totalData = response.data.length;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    validateEmp() {
      const now = new Date();
      const year = now.getFullYear() - 18;
      const maxDate = new Date(year, 11, 31); // December 31st of the calculated year

      // Convert dob to a Date object
      const dobDate = new Date(this.user.dob);

      // Check if dobDate is a valid date
      if (isNaN(dobDate.getTime())) {
        this.msg = "Invalid Date of Birth format";
        this.showsnackbar = true;
        return;
      }
      if (!this.user.employeeId) {
        this.msg = "Please provide employee id";
        this.showsnackbar = true;
        return;
      } else if (!this.user.name) {
        this.msg = "Please provide employee name";
        this.showsnackbar = true;
        return;
      } else if (!this.user.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.user.phonenumber) {
        this.msg = "Please provide employee phone no.";
        this.showsnackbar = true;
        return;
      }
      else if (dobDate > maxDate) {
        this.msg = "Please Provide valid Date of Birth";
        this.showsnackbar = true;
        return;
      }
      // else if (!this.user.address) {
      //   this.msg = "Please provide employee address";
      //   this.showsnackbar = true;
      //   return;
      // }
      else if (!this.user.gender) {
        this.msg = "Please provide gender";
        this.showsnackbar = true;
        return;
      }
      // else if (!this.department) {
      //   this.msg = "Please provide department";
      //   this.showsnackbar = true;
      //   return;
      // }
      else if (!this.user.positionid) {
        // if(!this.user.positionid.length>0) {
        this.msg = "Please provide position";
        this.showsnackbar = true;
        return;
        // }
      } else {
        this.addEmployee();
      }
    },

    addEmployee() {
      // this.user.departmentid = this.department;
      this.user.projectid = this.project;
      

      axios({
        url: "/employee/add/v2",
        method: "POST",
        data: this.user,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            if (response.data.data._id && this.coverImageFile)
              this.uploadCoverImages(response.data.data._id);
            else this.getData();
            this.user = {};
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadCoverImages(id) {
      this.flagg = false;
      this.appLoading = true;
      this.formDataCover.append("photo", this.coverImageFile);
      axios({
        method: "POST",
        url: "/employee/photo/upload/" + id,
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formDataCover = new FormData();
            this.msg = response.data.msg;
          } else {
            this.msg = "Can't Upload Profile Image.. Please Try Again Later";
            this.showSnackBar = true;
            this.formDataCover = new FormData();
          }
          this.getData();
          this.flagg = true;
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Profile Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },

    editcat(item) {
      // this.getUsers(item._id);
      this.getUsers1();
      this.getUsers2();
      this.getUsers3();
      this.department = null;
      this.project = null;
      var deptAr = [],
        projAr = [];
      this.editingitem = item;
      if (this.editingitem.dob) {
        this.editingitem.dob = this.editingitem.dob.slice(0, 10);

        this.getAge(this.editingitem.dob);
      }
      if (this.editingitem.dateofjoining) {
        this.editingitem.dateofjoining = this.editingitem.dateofjoining.slice(
          0,
          10
        );
      }
      if (this.editingitem.dateofProbationCompletion) {
        this.editingitem.dateofProbationCompletion =
          this.editingitem.dateofProbationCompletion.slice(0, 10);
      }
      if (this.editingitem.exitDate) {
        this.editingitem.exitDate = this.editingitem.exitDate.slice(0, 10);
      }
      // for (let i = 0; i < this.editingitem.positionid.length; i++) {
      //   if (this.editingitem.positionid[i].type == "Department") {
      // deptAr = this.editingitem.positionid[i].department;
      //   deptAr.push(this.editingitem.positionid[i].department);
      // }
      // if (this.editingitem.positionid[i].type == "Project") {
      //   projAr.push(this.editingitem.positionid[i].project);
      // }
      // }
      this.department = deptAr;
      this.project = projAr;
      this.editdialog = true;
    },

    validateEdit() {
      var phoneformat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      var emailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var phonenumber = String(this.editingitem.phonenumber);
      const now = new Date();
      const year = now.getFullYear() - 18;
      const maxDate = new Date(year, 11, 31); // December 31st of the calculated year

      // Convert dob to a Date object
      const dobDate = new Date(this.editingitem.dob);

      // Check if dobDate is a valid date
      if (isNaN(dobDate.getTime())) {
        this.msg = "Invalid Date of Birth format";
        this.showsnackbar = true;
        return;
      }

      if (!this.editingitem.employeeId) {
        this.msg = "Please provide employee id";
        this.showsnackbar = true;
        return;
      } else if (!this.editingitem.name) {
        this.msg = "Please provide employee name";
        this.showsnackbar = true;
        return;
      } else if (!this.editingitem.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (
        this.editingitem.email &&
        !this.editingitem.email.match(emailformat)
      ) {
        this.msg = "Please Provide valid Email";
        this.showsnackbar = true;
        return;
      } else if (!phonenumber) {
        this.msg = "Please provide employee phone no.";
        this.showsnackbar = true;
        return;
      } else if (phonenumber && !phonenumber.match(phoneformat)) {
        this.msg = "Please Provide valid Phone Number";
        this.showsnackbar = true;
        return;
      } else if (dobDate > maxDate) {
        this.msg = "Please Provide valid Date of Birth";
        this.showsnackbar = true;
        return;
      }
      // else if (!this.editingitem.address) {
      //   this.msg = "Please provide employee address";
      //   this.showsnackbar = true;
      //   return;
      // }
      else if (!this.editingitem.gender) {
        this.msg = "Please provide gender";
        this.showsnackbar = true;
        return;
      }
      //  else if (!this.department) {
      //    this.msg = "Please provide department";
      //    this.showsnackbar = true;
      //    return;
      //  }
      else if (!this.editingitem.positionid) {
        this.msg = "Please provide position";
        this.showsnackbar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      // console.log("this.editingitem==",this.editingitem)
      this.editingitem.id = this.editingitem._id;
      // data["name"] = this.editingitem.name;
      this.editingitem.positionid = this.test;
      this.editingitem.departmentid = this.department;
      // this.editingitem.role = this.role;

      this.editingitem.projectid = this.editingitem.projects;
      axios({
        url: "/employee/edit/v2",
        method: "POST",
        data: this.editingitem,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;
          this.appLoading = false;
          if (response.data.status) {
            if (this.coverImageFile) {
              this.uploadCoverImages(this.editingitem._id);
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
